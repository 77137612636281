* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.block {
  display: inline-block;
}

.info {
  position: absolute;
  left: 0;
  top: 0;
  font-family: monospace;
  font-size: 28px;
}
